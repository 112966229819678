import {
    Label,
    PolarGrid,
    PolarRadiusAxis,
    RadialBar,
    RadialBarChart,
} from "recharts";

import {ChartConfig, ChartContainer} from "@/components/ui/chart";


const chartConfig = {
    score: {
        label: "Efficiency score",
    },
    safari: {
        label: "Your processes",
        color: "hsl(var(--chart-2))",
    },
} satisfies ChartConfig;

interface ResultsChartProps {
    score: number
}

export function ResultsChart({score}: ResultsChartProps) {
    const fill = score < 6000 ? "#dc2626" : score < 8500 ? "#f4b400" : "#009688";
    const twfill = score < 6000 ? "fill-[#dc2626]" : score < 8500 ? "fill-[#f4b400]" : "fill-[#009688]";
    const chartData = [{score, fill}];
    return (
        <ChartContainer
            config={chartConfig}
            className="mx-auto aspect-square max-h-[250px]"
        >
            <RadialBarChart
                data={chartData}
                endAngle={(score / 10000) * 360}
                innerRadius={80}
                outerRadius={140}
            >
                <PolarGrid
                    gridType="circle"
                    radialLines={false}
                    stroke="none"
                    className="first:fill-gray-200 last:fill-white"
                    polarRadius={[86, 74]}
                />
                <RadialBar dataKey="score" background/>
                <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
                    <Label
                        content={({viewBox}) => {
                            if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                return (
                                    <text
                                        x={viewBox.cx}
                                        y={viewBox.cy}
                                        textAnchor="middle"
                                        dominantBaseline="middle"
                                    >
                                        <tspan
                                            x={viewBox.cx}
                                            y={viewBox.cy}
                                            className={`${twfill} text-4xl font-bold`}
                                        >
                                            {chartData[0].score.toLocaleString()}
                                        </tspan>
                                        <tspan
                                            x={viewBox.cx}
                                            y={(viewBox.cy || 0) + 28}
                                            className="fill-gray-500 text-md"
                                        >
                                            Your score
                                        </tspan>
                                    </text>
                                );
                            }
                        }}
                    />
                </PolarRadiusAxis>
            </RadialBarChart>
        </ChartContainer>
    );
}
