import {useEffect, useState} from "react";

import EfficiencyIndexSurvey, {ScoreTable} from "./EfficiencyIndexSurvey";
import {ResultsChart} from "./ResultsChart";
import {ScoreTableChart} from "@/components/lumen-efficiency-index/ScoreTableChart";

export default function Calculator() {
    const [score, setScore] = useState<number | null>(null);
    const [scoreTable, setScoreTable] = useState<ScoreTable>({});
    const [assessment, setAssessment] = useState("");

    useEffect(() => {
        if (score === null) {
            return
        }

        if (score < 6000) {
            setAssessment("Yikes, let's fix that!")
        } else if (score < 8500) {
            setAssessment("Your processes could be improved, let us help you with that.")
        } else {
            setAssessment("Great job! You probably don't need us at all. If you have shiny object syndrome, you can always hire us.")
        }

    }, [score])

    return (
        <div>
            {score !== null
                ? (
                    <div className="flex flex-col items-stretch justify-stretch gap-4">
                        <div>
                            <ResultsChart score={score}/>
                        </div>
                        {/* TODO: add score table chart */}
                        {/*<ScoreTableChart table={scoreTable}/>*/}
                        <div className="text-center">
                            {assessment}
                        </div>
                        <a
                            className="flex items-center justify-center h-10 px-4 py-2 text-sm font-semibold text-white transition-all rounded-lg bg-gradient-to-b from-purple-500 hover:to-purple-700 to-purple-600"
                            title="link to your page"
                            aria-label="your label"
                            href="#subscribe"
                        >
                            <span>Start today &nbsp; &nbsp; &rarr;</span>
                        </a>
                    </div>
                )
                : <EfficiencyIndexSurvey finalScoreCallback={(table, total) => {
                    setScore(total);
                    setScoreTable(table);
                }}/>
            }
        </div>
    );
}
